/* eslint-disable no-console */
import React, { useState } from 'react'

import {
  Block,
  Button,
  Flexbox,
  Header,
  modal,
  Paragraph,
  TextField,
} from '@stage-ui/core'
import Cube from '@src/components/Cube'
import { Checkmark } from '@stage-ui/icons'
import { metrikaGoal } from '@src/utils/metrikaGoal'

type UseFeedbackParams = {
  title: string
  allowAskQuestion?: boolean
  sendTitle?: string
}

const FeedbackForm = (props: UseFeedbackParams & { close: () => void }) => {
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [message, setMessage] = useState('')

  const [sent, setSent] = useState(false)

  const { title, allowAskQuestion, sendTitle, close } = props

  const send = () => {
    fetch(
      `${
        window.location.origin.match('http://')
          ? 'http://127.0.0.1:4000'
          : window.location.origin
      }/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
        mutation ($title: String! $name: String! $contact: String! $message: String!) {
          feedback(payload: {
            title: $title
            name: $name
            contact: $contact
            message: $message
          })
        }
      `,
          variables: { title, name, contact, message },
        }),
      },
    )
      .then(() => console.log('Feedback sent!'))
      .catch((error) => console.error((error as Error).message))

    setSent(true)
  }
  if (sent) {
    return (
      <Flexbox
        column
        centered
        p="2rem"
        style={{
          maxWidth: '20rem',
        }}
      >
        <Header align="center">Сообщение отправлено!</Header>
        <Flexbox
          centered
          my="4rem"
          w="6rem"
          h="6rem"
          borderRadius="6rem"
          backgroundColor="red600"
        >
          <Checkmark size="2rem" color="surface" />
        </Flexbox>
        <Button
          w="100%"
          p="1.75rem"
          decoration="outline"
          label="Вернутся на сайт"
          onClick={() => {
            close()
            metrikaGoal('clickFeedbackFinish')
          }}
        />
        <Cube
          position="absolute"
          hue={200}
          reverse
          style={{
            top: '-3rem',
            right: '-3rem',
          }}
        />
      </Flexbox>
    )
  }
  return (
    <Block
      p="2rem"
      style={{
        maxWidth: '20rem',
      }}
    >
      <Header>{title}</Header>
      <TextField
        my="1rem"
        mb="3rem"
        decoration="underline"
        labelType="inside"
        label="Ваше имя"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        my="1rem"
        decoration="underline"
        labelType="inside"
        label="Как с вами связаться?"
        value={contact}
        onChange={(e) => setContact(e.target.value)}
      />
      <Paragraph mb="3rem" size="s" color="gray600">
        Укажите удобный для Вас канал связи Telegram, WhatsApp, Tелефон или Email
      </Paragraph>
      {allowAskQuestion && (
        <TextField
          my="1rem"
          mb="3rem"
          decoration="underline"
          labelType="inside"
          multiline
          rows={2}
          label="Ввш вопрос"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      )}
      <Button
        w="100%"
        p="1.75rem"
        label={sendTitle || 'Отправить'}
        onClick={() => {
          send()
          metrikaGoal('clickFeedbackSend')
        }}
      />
      <Cube
        position="absolute"
        hue={150}
        reverse
        style={{
          top: '-3rem',
          right: '-3rem',
        }}
      />
    </Block>
  )
}

export const useFeedback = () => {
  return (params: UseFeedbackParams) => {
    modal({
      decoration: 'modal',
      hideHeader: true,
      render: (close) => <FeedbackForm {...params} close={close} />,
      didOpen: () => {
        metrikaGoal('modalFeedbackDidOpen')
      },
      didClose: () => {
        metrikaGoal('modalFeedbackDidClose')
      },
      overrides: {
        window: () => ({
          overflow: 'visible',
          padding: 0,
        }),
      },
    })
  }
}
